import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './layout/home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { MenusComponent } from './auth/menus/menus.component';


const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)
  },
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./home/home.module').then(module => module.HomeModule)
      },
      {
        path: 'setup',
        loadChildren: () => import('./systemsetup/systemsetup.module').then(module => module.SystemsetupModule)
      },
      {
        path: 'rhino',
        loadChildren: () => import('./rhino/rhino.module').then(module => module.RhinoModule)
      },
      {
        path: 'patrol',
        loadChildren: () => import('./patrol/patrol.module').then(module => module.PatrolModule)
      },
      {
        path: 'cases',
        loadChildren: () => import('./cases/cases.module').then(module => module.CasesModule)
      }
      ,
      {
        path: 'translocations',
        loadChildren: () => import('./translocation/translocation.module').then(module => module.TranslocationModule)
      },
      {
        path: 'conservation-partner',
        loadChildren: () => import('./conservationpartner/conservationpartner.module').then(module => module.ConservationpartnerModule)
      },
      {
        path:'reports',
        loadChildren: () => import('./report/report.module').then(module => module.ReportModule)
      }

    ]
  }




];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
