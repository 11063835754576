import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./shared/auth.interceptor";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { HomeComponent } from "./layout/home/home.component";
import { CustommaterialsModule } from "./custommaterials/custommaterials.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatInputModule } from "@angular/material/input";
import { ConfirmDialogComponent } from "./common/confirm-dialog/confirm-dialog.component";
import { NgxSpinnerModule } from "ngx-spinner";
@NgModule({
  declarations: [AppComponent, HomeComponent, ConfirmDialogComponent],
  imports: [
    CustommaterialsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    MatSnackBarModule,
    HttpClientModule,
    FlexLayoutModule,
    MatInputModule,
    NgxSpinnerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
