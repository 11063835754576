import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


// User interface
export class User {
  email: String;
  password: String;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private baseUrl = environment.baseURL;

  constructor(private http: HttpClient) { }

  // Login
  signin(user: User): Observable<any> {
    return this.http.post<any>(this.baseUrl+'login', user);
  }

  // Access authorised menus
  getAuthMenus(): Observable<any> {
    return this.http.get(this.baseUrl+'auth-menus');
  }

   // All Menus
   getMenus(query:any): Observable<any> {
    return this.http.get(this.baseUrl+'menus',{ params: query });
  }

  // Get all permissions
  getPermissions(): Observable<any> {
    return this.http.get(this.baseUrl+'permissions');
  }

  // Asign permissions to menu
  postMenuPermissions(data): Observable<any> {
    return this.http.post<any>(this.baseUrl+'menus/permissions', data);
  }

}
