import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { TokenService } from "../shared/token.service";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    this.spinner.show();
    const accessToken = this.tokenService.getToken();
    req = req.clone({
      setHeaders: {
        Authorization: "Bearer " + accessToken,
      },
    });
    return next.handle(req).pipe(
      tap(
        (res) => {
          if (res instanceof HttpResponse) {
            this.spinner.hide();
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.tokenService.removeToken();
              //this.router.navigate(['login']);
              this.router.navigate(['landing']);
            }
          }
        })
    );
  }
}
