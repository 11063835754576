<!-- spinner -->
<!-- <div class="spinner-container">
  <div *ngIf="sharedService.isLoading | async">
    <mat-progress-bar mode="indeterminate" style="color: goldenrod;"></mat-progress-bar>
  </div>
</div> -->

<mat-toolbar class="mat-elevation-z8">
  <button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()" class="toggle-button">
    <mat-icon *ngIf="!sidenav.opened">
      menu
    </mat-icon>
    <mat-icon *ngIf="sidenav.opened">
      close
    </mat-icon>
  </button>
  <span [ngStyle]="{'padding-left.rem':1}">TRIMS</span>
  <!--  <span><img class="logo-img" src="/assets/images/trims_logo_white_new_small_bd.png" width="60px"
      height="auto" /></span> -->
  <span class="spacer"></span>
  <h2 style="cursor: pointer;" (click)="signOut()">Logout</h2>

</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z8">
    <div class="logo-image d-none d-md-block" fxLayout="row wrap" fxLayoutAlign="start center" fxFlexFull
      [ngStyle]="{'padding-bottom.rem':1}" routerLink="/dashboard">
      <img src="/assets/images/logo.png" alt="" class="log-img" fxFlex="30" />
      <div fxFlex="70" [ngStyle]="{'padding-left.rem':1}">
        <h3><b>{{user|uppercase}}</b></h3>
        <p>{{station|uppercase}}</p>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="navigation-menu" fxLayout="column" [ngStyle]="{'padding-top.rem':1}">

      <!-- new menu -->
      <mat-accordion>
        <!-- <mat-expansion-panel hideToggle [disabled]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class="expansion-title">
              <div [routerLink]="['/dashboard']" routerLinkActive="selected" [routerLinkActiveOptions]="{ exact: true }"
                mat-button class="menu-button menu-group">
                <mat-icon>dashboard</mat-icon>
                <span>Dashboard</span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel> -->

        <mat-expansion-panel *ngFor="let group of menus">
          <mat-expansion-panel-header class="arrow">
            <mat-panel-title class="expansion-title">
              <div class="menu-button menu-group">
                <mat-icon>{{group.icon}}</mat-icon>
                <span>{{group.name}}</span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="sub-menu">
            <button *ngFor="let menu of group.menus" routerLink="{{menu.path}}" routerLinkActive="selected" mat-button
              class="menu-button">
              <span>{{menu.name}}</span>
            </button>
          </div>
        </mat-expansion-panel>

      </mat-accordion>

      <!-- new menu -->


    </div>
  </mat-sidenav>
  <mat-sidenav-content style="padding: 2rem 3rem;">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>