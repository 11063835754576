import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { SharedserviceService } from 'src/app/shared/services/sharedservice.service';
import { TokenService } from '../../shared/token.service';
import { AuthStateService } from '../../shared/auth-state.service';
import { AuthService } from '../../shared/auth.service';

@Component({
  selector: 'trims-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  styles: [],
})
export class HomeComponent implements OnInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  user: any;
  station:any;
  isSignedIn: boolean;
  menus = [];

  constructor(
    private observer: BreakpointObserver,
    private router: Router,
    public sharedService: SharedserviceService,
    public auth: AuthStateService,
    public token: TokenService,
    public authService: AuthService,
    private dialog: MatDialog) { }

  ngAfterViewInit() {
    this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1))
      .subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });
  }

  ngOnInit() {
    this.auth.userAuthState.subscribe(val => {
        this.isSignedIn = val;
    });
    this.authService.getAuthMenus().subscribe((data)=>{
      this.menus = data.menus;
    })

    this.user = localStorage.getItem("username");
    this.station = localStorage.getItem("station");
  }

  // Signout
  signOut() {
    this.auth.setAuthState(false);
    this.token.removeToken();
    this.router.navigate(['']);
  }

}
