<h1 mat-dialog-title>
  {{title}}
</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<div>
  <div fxLayoutAlign="end center" fxLayoutGap="5px">
    <button mat-button color="ascent" (click)="onDismiss()">No</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
  </div>
</div>
