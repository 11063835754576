import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedserviceService {

  
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  
  constructor(public snackBar: MatSnackBar, private httpClient: HttpClient) { }

  private handleError(errorResponse: HttpErrorResponse){
    return throwError(errorResponse.error); 
  }
  
  //Success snark
  openSuccessSnackBar(message: string) {
    this.snackBar.open(message, 'close', {
      duration: 5000,
      panelClass: ['successSnarkBar'],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  //Failure snark
  openFailureSnackBar(message: string) {
    this.snackBar.open(message, 'close', {
      duration: 5000,
      panelClass: ['failureSnarkBar'],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
